.footer {
  background-color: black;
  text-align: center;
  /* position: absolute; */
  /* padding: 30px 0; */
  /* position: absolute;
  bottom: 0; */
}
.footer_header {
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
}
.footer_desc {
  font-size: 22px;
  line-height: 36px;
  font-weight: 400;
  color: #fff;
  margin-top: 16px;
}
.footer_links {
  text-align: center !important;
  align-items: baseline !important;
  color: #fff;
}
.footer_links p {
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none !important;
  /* align-items: baseline !important; */
}

.footer_icons .fa {
  color: #fff;
  font-size: 26px;
}
@media (max-width: 425px) {
  .footer_desc {
    font-size: 18px;
  }
}
.underline_text {
  text-decoration: none;
}
.underline_text:hover {
  text-decoration: none;
}

.bottom-border {
  margin-top: 30px;
  border-bottom: 1px solid black;
}
