#chat_model_body {
    display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
}
.student_name {
    width: 33%;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    padding-left: 40px;
  }
  .bookingcard {
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 60px !important;
  }
  .iconStyle {
    color: white;
  }
  
  h4 {
    color: white;
  }
  
  h5,
  .card-title h3 {
    color: rgba(227, 130, 38, 1) !important ;
  }
  
  .detail_icon{
    width: 50px;
    text-align: center;
  }
  .card-text {
    font-weight: 400 !important;
    color: rgba(227, 130, 38, 1) !important ;
  }
  .card-text-value{
    font-weight: 400 !important;
    color: #fff !important; 
  }