.card {
  background: linear-gradient(
    354.9deg,
    rgba(113, 113, 113, 0.1) 4.41%,
    rgba(255, 255, 255, 0.1) 91.68%
  );
  /* font-family: "Montserrat";
  font-style: normal; */
}
.bottom-border {
  border-bottom: 1px solid #626262;
}
.about-me {
  margin-top: 20px;
  margin-bottom: 20px;
  /* font-family: "Montserrat";
  font-style: normal; */
}

.venue-card {
  margin-top: 20px;
  height: 400px;
  /* font-family: "Montserrat";
  font-style: normal; */
  text-align: start;
}

.review-container1 {
  margin-top: 20px;
  margin-bottom: 20px;
  /* font-family: "Montserrat";
  font-style: normal; */
}
a {
  text-decoration: none !important;
  color: white !important;
}
a:hover {
  color: #e38226 !important;
}

.side_detail {
  display: flex !important;
  justify-content: space-around !important;
  margin-top: 20px;
  /* font-family: "Montserrat";
  font-style: normal; */
  font-size: x-large;
}
/* .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  padding: 0;
  color: black;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 15%;
  padding: 0;
  color: black;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1.5;
  transition: opacity 0.15s ease;
}
.carousel-control-next {
  margin-left: 10px !important;
  color: #ba6e3f;
} */
.carousel-indicators {
  border-radius: 50%;
  border: none;
  top: 310px;
}

h3 {
  color: #e38226;
}

.book_lesn_btn {
  width: 60% !important;
  height: 40px;
  margin: 5px auto;
  background: #e38226;
  border: none;
  align-items: center;
  color: white;
  font-size: x-large;
}
