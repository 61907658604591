.about {
  margin: 0;
  padding: 0;
  background-image: url("../../assets/images/aboutus_page.jpg");
  /* opacity: 0.7; */
  background-size: cover;
  background-position: center;
  min-height: 65vh;
  min-width: 99vw;
}
.aboutcards {
  border-radius: 20px;
  border: 1px solid white;
  background-color: #000;
}
.about_header {
  padding-top: 200px;
  font-size: 64px;
  text-align: center;
  font-weight: 800;
  color: white;
  /* line-height: 92px; */
  text-transform: uppercase;
}
.dark_bg {
  height: auto;
  background-color: #000 !important;
  display: flex;
  justify-content: center;
}
.newsletter_form {
  height: auto;
  background-color: #000;
  /* position: absolute; */
  top: 65%;
  /* left: 15%; */
  /* max-width: 100% !important; */
}
.main_box {
  justify-content: space-evenly;
  padding: 50px 0 !important;
}
.form_box {
  text-align: center;
  margin: 10px 0 !important;
}
.newsletter_inp {
  background: transparent !important;
  width: 100% !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 30px 10px !important;
}
.newsletter_btn {
  padding: 16px 35px !important;
  border: none !important;
  background-color: #e38226 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  width: 100% !important;
  border-radius: 0px !important;
}
.about_cards {
  background-color: #000;
  padding: 10px 0;
  /* border-radius: 30px; */
}
/* .about_box {
  margin-top: 35px !important;
} */
.about_box h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  color: #fff;
  padding-top: 20px !important;
  display: flex;
  /* justify-content: center; */
}
.about_box {
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  color: #fff;
  /* margin-top: 35px; */
  display: flex;
  text-align: start;
  display: flex;
  flex-direction: column;
  /* padding-right: 100px; */
}
.about_box_list {
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  color: #fff;
  /* margin-top: 35px; */
  display: flex;
  justify-content: space-evenly;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
}
.about_para {
  font-size: 22px;
  line-height: 34px;
  font-weight: 300;
  color: #fff;
  /* margin-top: 35px; */
  display: flex;
  /* justify-content: center; */
  text-align: center;
  display: flex;
  flex-direction: row;
}

.about_box_h2 {
  /* font-size: 20px; */
  line-height: 34px;
  /* font-weight: 600; */
  color: #fff;
  /* margin-top: 50px; */
  display: flex;
  text-decoration: none;
  justify-content: center;
}

.side_text {
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 0 !important;
  text-align: center;
  margin-bottom: 50px;
  /* padding-right: 70px !important; */
}
.side_icon {
  padding-top: 63px;
  justify-content: center;
  /* align-items: center; */
}

/* Games */

/* .carousel {
  margin: 30px auto 60px;
  padding: 0 80px;
} */
.carousel .carousel-item {
  text-align: center;
  overflow: hidden;
}
.carousel .carousel-item h4 {
  font-family: "Varela Round", sans-serif;
}
.carousel .carousel-item img {
  max-width: 100%;
  display: inline-block;
}
.carousel .carousel-item .btn {
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  background: #a177ff;
  padding: 6px 15px;
  margin-top: 5px;
}
.carousel .carousel-item .btn:hover {
  background: #8c5bff;
}
.carousel .carousel-item .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}
.carousel .thumb-wrapper {
  margin: 5px;
  background: #000;
  padding: 40px 10px;
  width: 99%;
  /* border-radius: 15px !important;
  border: 1px solid #fff !important; */
}
.carousel .thumb-wrapper .img-box {
  text-align: center;
}
.carousel .thumb-content {
  padding: 15px;
  font-size: 13px;
  text-align: center;
  background-color: #313131;
  color: #c9c9c9;
  border: none;
}
.carousel .thumb-content .team_card_desc {
  font-size: 20px;
  color: #e38226;
  font-weight: 400;
}

@media (max-width: 425px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: none !important;
  }
}
/* @media (max-width: 1440px) {
  .newsletter_form {
    left: 4%;
  }
}

@media (max-width: 1050px) {
  .newsletter_form {
    left: 4%;
  }
}
@media (max-width: 1000px) {
  .newsletter_form {
    left: 0%;
    max-width: 100% !important;
  }
} */
@media (max-width: 1405px) {
  .newsletter_btn {
    padding: 16px 5px !important;
    border: none !important;
    background-color: #e38226 !important;
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
}
.carousel-control-prev i,
.carousel-control-next i {
  font-size: 36px;
  position: absolute;
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
}
.carousel-control-prev i {
  margin-right: 200px;
  color: #ba6e3f;
}
.carousel-control-next i {
  margin-left: 200px;
  color: #ba6e3f;
}

@media (max-width: 980px) {
  .carousel-control-next i {
    margin-left: 90px !important;
  }
  .carousel-control-prev i {
    margin-right: 90px !important;
  }
}
@media (max-width: 590px) {
  .carousel-control-next i {
    margin-left: 60px !important;
  }
  .carousel-control-prev i {
    margin-right: 60px !important;
  }
}
.carousel-indicators {
  bottom: -50px;
}
.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -8px 4px;
  border: none;
}
.carousel-indicators li {
  background: #ababab;
}
.carousel-indicators li.active {
  background: #555;
}
.team {
  background-color: #000;
  padding: 80px 0;
}
.team_header {
  text-align: center;
  color: #e38226;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
}
.team_desc {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  padding-top: 10px;
  font-weight: 700;
}
@media (max-width: 1205px) {
  .side_text {
    padding-right: 10px !important;
    padding-left: 20px !important;
  }
}
@media (max-width: 1002px) {
  .side_text {
    padding-right: 0px !important;
    padding-left: 20px !important;
  }
  .about_box p {
    padding-right: 50px;
  }
}
@media (max-width: 768px) {
  .about_header {
    font-size: 40px !important;
    padding-top: 110px !important;
  }
  .side_text {
    padding-right: 0px !important;
    padding-left: 19px !important;
    /* text-align: center; */
  }
  .about_box p {
    padding: 10px;
    /* text-align: center; */
  }
  .side_icon {
    margin: 0 auto !important;
    display: flex;
  }
  .about_box h1 {
    text-align: center;
    padding-top: 50px !important;
  }
  .about_cards {
    background-color: #000 !important;
    padding: 100px 0;
  }
}
@media (max-width: 425px) {
  .about_header {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    color: #fff;
    padding-top: 80px !important;
  }
  .side_text {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: center;
  }
  .about_box p {
    padding: 10px;
    text-align: center;
  }
  .side_icon {
    margin: 0 auto !important;
    display: flex;
  }
  .about_box h1 {
    text-align: center;
    padding-top: 50px !important;
  }
  .about_cards {
    background-color: #313131;
    padding: 100px 0;
  }
}
