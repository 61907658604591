/* @font-face {
  font-family: "GothamBold";
  src: local("GothamBold"),
    url("./assets/fonts/GothamBold.ttf") format("truetype");
} */

body {
  margin: 0;
  font-family: GothamBold !important;
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */

  /* font-family: "GothamBold", cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: GothamBold, sans-serif;
}

.cursor_pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #595959;
  box-shadow: inset 0 0 none rgb(41, 40, 40);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f9b87a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e38226;
}

/* input[id^="chatbox-input"]::-webkit-input-placeholder { 
  color: #e38226;
} */

input[id^="chatbox-input"]::-webkit-input-placeholder {
  color: #000 !important ;
}
/* input[id^="chatbox-input"]::-moz-placeholder { 
  color: #e38226 !important ;
}
input[id^="chatbox-input"]:-ms-input-placeholder { 
  color: #e38226 !important ;
}
input[id^="chatbox-input"]:-moz-placeholder { 
  color: #e38226 !important ;
} */

.ttButton {
  /* margin: 10px auto; */
  background: #fff !important;
  border: none !important;
  /* padding: 14px !important; */

  font-size: 18px !important;
}

.autoButton {
  padding: 10px 35px !important;
  border: none !important;
  background-color: #e38226 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  border-radius: 0px !important;
}

.autoButtonAnimation {
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11) !important;
}

.autoButtonAnimation:hover {
  transform: scale(1.2);
  box-shadow: sienna;
}

.grayButton {
  padding: 10px 35px;
  border: none;
  background: linear-gradient(
    354.9deg,
    rgba(113, 113, 113, 0.1) 4.41%,
    rgba(255, 255, 255, 0.1) 91.68%
  );
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
}

.grayButtonAnimation {
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11) !important;
}

.grayButtonAnimation:hover {
  transform: scale(1.2);
  box-shadow: sienna;
}

.grayButtonActive {
  padding: 10px 35px;
  border: none;
  background: #e38226;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 50px;
}

.iconButton {
  padding: 0px 35px;
  border: none;
  background: none !important;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border-radius: 0px;
}

.iconButtonAnimation {
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11) !important;
}

.iconButtonAnimation:hover {
  transform: scale(1.2);
  box-shadow: sienna;
}
.coach_full_price {
  margin: auto 0px !important;
}
.main_ttButton {
  padding: 10px 35px;
  border: none;
  background-color: #e38226 !important;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  border-radius: 0px;
}

.main_container {
  min-height: 70vh !important;
}
.main_container_banner {
  min-height: 24.09vh !important;
}

.ttButtonAnimation {
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11) !important;
}

.ttButtonAnimation:hover {
  transform: scale(1.2);
  box-shadow: sienna;
}

.main_ttButton_active {
  padding: 10px 35px !important;
  border: none !important;
  color: #e38226 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 100% !important;
  border-radius: 0px !important;
  transform: scale(1.2);
  background-color: #fae1c9 !important;
}

.input-control {
  position: relative !important;
  background: #313131 !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  height: 50px !important;

  /* position: relative; */
}
.btn-close {
  opacity: 1.5 !important;
}
.modal-header {
  border-bottom: 1px solid !important;
}
.was-validated {
  padding-right: 0px !important;
}

.required {
  color: #e38226;
  position: absolute;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.3em;
  right: 0;
  top: 35%;
}

#chatsection {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 2px;
}

#chatsection:hover {
  background-color: #555;
}

#scrollToTop {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99999;
  font-size: 18px;
  border: none;
  outline: none;
  color: #e38226;
  cursor: pointer;
  padding: 15px;
  border-radius: 2px;
}

#scrollToTop:hover {
  background-color: #555;
}

/* for chat  */

.__main {
  max-width: 90%;
  margin: auto;
  background-color: black;

  min-height: 80vh;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  /* display: flex; */
  padding-left: 0;
}

.main__chatbody {
  flex-grow: 1;
  background-color: #000000;
  /* border-radius: 10px; */
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* /// */

.main__chatlist {
  border-right: 1px solid #595959;
  padding: 0 40px 0 0px;
  width: 300px;
}
.chatlist__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.btn-nobg {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  color: #dad9dd;
  outline: none;
}
.search_wrap {
  background-color: #000;
  border-radius: 5px;
}
.search_wrap input {
  background-color: transparent;
  border: none;
  padding: 15px 15px;
  outline: none;
  width: 80%;
  padding-right: 0;
}
.search-btn {
  height: 46px;
  border: none;
  background-color: transparent;
  outline: none;
  width: 20%;
  cursor: pointer;
  font-size: 20px;
}
.chatlist__items {
  margin-top: 30px;
  overflow: auto;
  max-height: calc(100vh - calc(100vh / 2));
  height: 64vh;
  overflow-x: hidden;
}

.chatlist__item {
  display: flex;
  border-bottom: 1px solid #595959;
  padding-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
  transform: scale(0);
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.1s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chatlist__item:first-child {
  margin-top: 0;
}
.avatar {
  width: 80px;
  /* height: 100px; */
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
}
.avatar img {
  max-width: 100%;
  object-fit: cover;
}

.chatlist__item .userMeta p {
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: 600;
  font-size: 14px;
}
.chatlist__item .userMeta span {
  margin: 0;
  padding: 0;
  color: #ceccd3;
  font-weight: 400;
  font-size: 12px;
  display: block;
}
.chatlist__item:hover,
.chatlist__item.active {
  background: #fae1c9;

  border-radius: 10px;
}
.isOnline {
  position: absolute;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  border-radius: 50%;
  border: 2px solid #fff;
}
.isOnline.active {
  background-color: tomato;
}
.avatar-img {
  overflow: hidden;
  border-radius: 50%;
  width: 100%;
  /* height: 100%; */
}

/* ///chat content/ */

.main__chatcontent {
  flex-grow: 1;
  padding: 20px 40px;
  max-width: 100%;
}
.content__header {
  padding-bottom: 15px;
  border-bottom: 1px solid #595959;
}
.current-chatting-user {
  display: flex;
  align-items: center;
  color: #fff;
}
.current-chatting-user p {
  margin: 0;
  font-weight: 600;
}
.content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .settings .btn-nobg {
  color: #000;
} */
.content__body {
  /* max-height: calc(100vh - calc(100vh / 2)); */
  padding: 8px;
  height: 64vh;
  overflow: auto;
  overflow-x: hidden;
}
.chat__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  transform: scale(0);
  transform-origin: right;
  animation-name: showIn;
  animation-duration: 0.2s; /* or: Xms */
  animation-iteration-count: 1;
  animation-direction: normal; /* or: normal */
  animation-timing-function: cubic-bezier(
    0.88,
    0.19,
    0.37,
    1.11
  ); /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */
  animation-fill-mode: both; /* or: backwards, both, none */
  animation-delay: 0.2s; /* or: Xms */
}
@keyframes showIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.chat__item .avatar {
  margin-right: 0px;
  margin-left: 20px;
  background: #fff;
  padding: 1px;
}
.chat__item__content {
  background-color: #e38226;
  color: #fff;
  padding: 5px 5px;
  border-radius: 10px 10px 0 10px;
  max-width: 90%;
  min-width: 215px;
}
.chat__item__content .chat__meta {
  justify-content: space-between;
  display: flex;
  margin-top: 10px;
}
.chat__item__content .chat__meta span {
  font-size: 14px;
  color: #fff;
  user-select: none;
}
.chat__msg {
  user-select: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.chat__item.other {
  flex-direction: row-reverse;
  transform-origin: left;
}
.chat__item.other .chat__item__content {
  background-color: #151515;
  color: #fff;
  border-radius: 10px 10px 10px 0;
  max-width: 90%;
}
.chat__item.other .avatar {
  margin-right: 20px;
  margin-left: 0px;
}
.chat__item.other .chat__item__content .chat__meta span {
  color: #d1d1d1;
}
.content__footer {
  padding-top: 30px;
}
.sendNewMessage {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
}

.sendNewMessage_outer {
  background-color: #fff;
  /* max-height: 300px; */
  height: auto;
  padding: 10px;
  /* justify-content: space-between; */
  margin: 10px 0px;
  border-radius: 8px;
}
.sendMessagePreview {
  overflow-y: scroll;
  max-height: 200px;
  box-sizing: border-box;
}
#main_button {
  background-color: #fae1c9;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #e38226;
  padding: 16px;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.preview_image {
  background-color: #fae1c9;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #e38226;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.preview_video {
  width: 100%;
  background-color: #fae1c9;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #e38226;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button {
  width: 36px;
  height: 36px;
  background-color: #fae1c9;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #e38226;
  padding: 0;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}
.sendNewMessage button:hover {
  transform: scale(1.2);
}
.sendNewMessage button i {
  display: block;
}
.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: transparent;
  border: none;
  outline: none;
}
#sendMsgBtn {
  background-color: #e38226;
  color: #fff;
}

.google-analytics > .css-2b097c-container > .css-yk16xz-control,
.css-1pahdxg-control {
  background: #313131 !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  height: 50px !important;
}
.css-1uccc91-singleValue {
  color: #fff !important;
}
#react-select-2-input {
  color: #fff !important;
}
.css-1wa3eu0-placeholder {
  color: #fff !important;
}
.css-1g6gooi {
  color: #fff !important;
}
.dropdown-container {
  background: #313131 !important;
  color: #000 !important;
  border-radius: 0 !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-heading-value,
.gray {
  color: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  select-input */
.select_input {
  background: #313131 !important;
  width: 100% !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  height: 59px !important;
  margin-top: 30px !important;
  position: relative;
}

.select_arrow {
  position: absolute;
  top: 40px;
  right: 25px;
}
.select-caret {
  position: absolute;
  top: 25px;
  right: 28px;
}

.ttlButton {
  width: auto !important;
  margin: 20px auto !important;
  background: #e38226 !important;
  border: none !important;
  padding: 10px 20px !important;
  color: #fff !important;
  font-size: 16px !important;
}

#toggleCard {
  display: none;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#center_div {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.main_basic_detail_form {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}
.basic_detail_form {
  background-color: #313131 !important;

  padding: 80px 0px !important;
}

.rti--container {
  background: #313131 !important;
  border-radius: 0px !important;
}
.rti--input {
  color: white !important;
  /* cursor: white !important; */
  background: #313131 !important;
  width: 50% !important;
  padding: 5px 0px !important;
}

.input-arrow {
  position: absolute;
  top: 60px;
  right: 27px;
}

.name_section {
  width: 85%;
  display: flex;
  padding: 15px 0;
  margin: 10px auto;
  background: linear-gradient(
    180deg,
    rgb(68 68 68 / 50%) 0%,
    rgb(0 0 0 / 0%) 50%,
    rgb(48 48 48 / 90%) 100%
  );
}

@media screen and (max-width: 1200px) {
  #toggleList {
    display: none;
  }
  #toggleCard {
    display: block;
  }
}
