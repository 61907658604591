.bg-background {
  background-color: #000 !important;
}
.nav-items {
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
}
.nav-item {
  margin: 0 20px !important;
}
/* Specific styles for non-active links */
.link {
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.link:hover {
  color: #fff;
  border-bottom: none !important;
}
.nav-items:hover {
  border-bottom: none !important;
}

/* Specific styles for active links */
.link-active {
  color: #ba6e3f;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  border-bottom: 1px solid #ba6e3f;
}

.link-active:hover {
  color: #fff;
}

.signin-btn {
  border-radius: 0px !important;
  border: 1px solid #ba6e3f !important;
  color: #ba6e3f !important;
  background-color: #000 !important;
  padding: 8px 23px !important;
}

.signup-btn {
  border-radius: 0px !important;
  border: 1px solid #ba6e3f !important;
  border: none;
  margin-left: 8px !important;
  /* color: #111 !important; */
  background-color: #ba6e3f !important;
}
.dropdown-toggle {
  color: #fff !important;
}
.menu_items {
  text-align: center !important;
  padding: 20px 25px !important;
}
.border_btn {
  border: 1px solid #ba6e3f !important;
  margin: 10px 0 !important;
  padding: 8px 9px !important;
}
.border_btnn {
  border: 1px solid #ba6e3f !important;
  margin: 10px 0 !important;
  padding: 3px 9px !important;
  line-height: 21px !important;
}
.dropdown-menu {
  text-align: center !important;
  padding: 15px !important;
}

element.style {
  color: #fff;
}
.dropdown-menu.show {
  width: 190px !important;
}
.align_navbar {
  margin-left: 50% !important;
}
/* .align_btn {
  margin-left: 250px !important;
} */

.logohandler {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  cursor: pointer;
}

@media (max-width: 1800px) {
  .align_navbar {
    margin-left: 40% !important;
  }
}
@media (max-width: 1550px) {
  .align_navbar {
    margin-left: 30% !important;
  }
}
@media (max-width: 1350px) {
  .align_navbar {
    margin-left: 20% !important;
  }
}
@media (max-width: 1212px) {
  .align_navbar {
    margin-left: 10% !important;
  }
}
@media (max-width: 1112px) {
  .align_navbar {
    margin-left: 0% !important;
  }
}
@media (max-width: 990px) {
  .align_btn {
    margin-left: 0% !important;
  }
}
@media (max-width: 768px) {
  .align_btn {
    margin-left: 0% !important;
  }
  .logohandler {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 425px) {
  .align_btn {
    margin-left: 0% !important;
  }
  .align_navbar {
    margin-left: 0% !important;
  }
}
.dropdown-menu[data-bs-popper] {
  padding: 15px !important;
}
.navbar-toggler {
  background: #e38226 !important;
}
.nav-link {
  color: #e38226 !important;
}
.menu_colour {
  background: #111 !important;
  width: 10% !important;
  margin: 2px 10px !important;
}
.dropdown-item {
  color: #fff !important;
  cursor: pointer;
}
.dropdown-item:hover {
  background: #e38226 !important;
}
.bg-clr {
  color: #fff !important;
}
