.contact {
  margin: 0;
  padding: 0;
  background-color: #000000;
  /* background-image: url("../../assets/images/contactus-bg.png"); */
  background-size: cover;
  background-position: center;
  min-height: 65vh;
  min-width: 99vw;
  height: auto;
  /* background: black; */
}
.contact_header h1 {
  padding-top: 200px;
  font-size: 64px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  line-height: 92px;
  text-transform: uppercase;
}
.contact_form {
  height: auto;
  background-color: black;
  /* position: relative; */
  top: 65%;

  /* max-width: 100% !important; */
}
.contact_box {
  justify-content: center;
  padding: 50px 0 !important;
}
.form_box {
  text-align: center;
  margin: 10px 0 !important;
}
.newsletter_inp {
  background: transparent !important;
  width: 100% !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 30px 10px !important;
}
.newsletter_select {
  background: black !important;
  width: 100% !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: 15px !important;
  position: relative;
}
.set_arrow {
  position: absolute;
  top: 25px;
  right: 40px;
}
.bg_form {
  display: flex;
  justify-content: center;
  background: black;
  height: 550px;
}
.bg_forms {
  background: #000000;
  height: 300px;
}

@media (max-width: 425px) {
  .contact_header h1 {
    padding-top: 100px !important;
    font-size: 28px !important;
    text-align: center;
    font-weight: 800;
    color: #fff;
    line-height: 42px;
    text-transform: uppercase;
  }
}
@media (max-width: 768px) {
  .contact_header h1 {
    padding-top: 100px;
    font-size: 38px !important;
    text-align: center;
    font-weight: 600;
    color: #fff;
    line-height: 52px;
    text-transform: uppercase;
  }
  .bg_forms {
    display: none;
  }
}
@media (max-width: 1024px) {
  .contact_header h1 {
    padding-top: 100px;
    font-size: 38px;
  }
}
@media (max-width: 1440px) {
  .contact_header h1 {
    padding-top: 100px;
    font-size: 54px;
    padding-bottom: 80px;
  }
}
