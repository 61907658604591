.select_box {
  background: #313131 !important;
  width: 75% !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  height: 59px !important;
  margin-top: 30px !important;
  position: relative;
}
.checkbox_text {
  font-size: 18px;
  color: #d4d4d4;
  padding-left: 10px;
}
.inner_textt {
  color: #e38226;
  padding: 0 6px;
  cursor: pointer;
}
.signup_content {
  margin-top: 10px !important;
}

.set_arrows {
  position: absolute;
  top: 42px;
  right: 110px;
}

.form_signup {
  min-height: 100% !important;
  padding-top: 40px !important;
}
.personal_form {
  min-height: 88% !important;
  background-color: #313131 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  max-width: 45% !important;
  margin: 0px auto !important;
  padding: 20px 0;
}
.personal_form h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  color: #e38226;
  margin-bottom: 45px;
  text-align: center;
}
.basic_form {
  min-height: 88% !important;
  background-color: #313131 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  max-width: 45% !important;
  margin: 0px auto !important;
  padding: 40px 0 !important;
}
.basic_form h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  color: #e38226;
  margin-bottom: 45px;
  text-align: center;
}
.personalinfo_form {
  min-height: 88% !important;
  background-color: #313131 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  max-width: 47% !important;
  margin: 0px auto !important;
  padding: 6px 0 !important;
}
.personalinfo_form h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  color: #e38226;
  margin-bottom: 45px;
  text-align: center;
}
@media (max-width: 1024px) {
  .personal_form {
    max-width: 65% !important;
  }
  .personalinfo_form {
    max-width: 65% !important;
  }
  .basic_form {
    max-width: 65% !important;
  }
}
@media (max-width: 768px) {
  .personal_form {
    max-width: 90% !important;
  }
  .personalinfo_form {
    max-width: 90% !important;
  }
  .basic_form {
    max-width: 90% !important;
  }
  .personal_form h1 {
    font-size: 46px;
  }
  .personalinfo_form h1 {
    font-size: 46px;
  }
  .basic_form h1 {
    font-size: 46px;
  }
}
