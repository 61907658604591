.profile {
  /* display: flex;
  justify-content: center; */
  width: 100%;
  /* align-items: center;
  height: 100%; */
}
.profile_form {
  padding-top: 10px;
  min-height: 80%;
  min-width: 30%;
  /* background-color: #313131; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid red; */
  margin: 20px auto;
}

.profile_inp {
  background: transparent !important;
  width: 100% !important;
  margin: 12px auto !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 30px 10px !important;
  margin-top: 10px !important;
  position: relative;
}
.error_text {
  position: absolute;
  top: 91%;
  left: 13%;
}

.profile_btn {
  padding: 12px 35px !important;
  border: none !important;
  background-color: #e38226 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  /* width: 62% !important; */
  border-radius: 0px !important;
}
.required-asterisk {
  color: #e38226;
  position: absolute;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.3em;
  right: 11%;
  top: 32%;
}
