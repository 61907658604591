#dashboard {
  margin: 0;
  padding: 0;
  background-image: url("../../assets/images/dashboard-bg.png");
  background-size: cover;
  background-position: center;
  height: 300px;
  min-width: 98vw;
}
.dashboard_desc h1 {
  padding-top: 100px;
  font-size: 64px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  line-height: 92px;
  text-transform: uppercase;
}

#sidebar {
  padding-top: 50px;
  padding-left: 70px;
  padding-right: 70px;
  background: #313131 !important;
  height: -webkit-fill-available;
}
.list-group-item {
  border: none !important;
  padding-top: 60px 0;
  font-size: 20px;
  font-weight: 600;
  /* color: #515151  !important; */
  background: #313131 !important;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
}
.right_sidebar {
  background: #313131 !important;
  color: #fff !important;
}
.container-height {
  height: 100%;
}

.main_fixed {
  height: auto;
  overflow-y: scroll;
}

@media (max-width: 1024px) {
  #sidebar {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 768px) {
  #sidebar {
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .dashboard_desc h1 {
    padding-top: 90px;
    font-size: 54px;
  }
}
@media (max-width: 425px) {
  #sidebar {
    padding-top: 50px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .dashboard_desc h1 {
    padding-top: 50px;
    font-size: 44px;
  }
  .list-group-item {
    border: none !important;
    padding-top: 60px 0;
    font-size: 20px;
    font-weight: 600;
    color: #515151 !important;
    background: #313131 !important;
    text-align: left;
    text-decoration: none !important;
    cursor: pointer;
  }
}
