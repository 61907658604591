.privacy_conatiner {
  padding: 70px 0;
}
.privacy {
  margin: 0;
  padding: 0;
  background-image: url("../assets/images/privacy-bg.png");
  background-size: cover;
  background-position: center;
  min-height: 65vh;
  min-width: 99vw;
}
.privacy_content h1 {
  padding-top: 200px;
  font-size: 64px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  line-height: 92px;
  text-transform: uppercase;
}
.privacy_conatiner {
  background-color: #313131;
}
.date {
  font-size: 24px;
  color: #e38226 !important;
  text-align: left;
  padding-top: 15px;
}
.header_privacy {
  text-align: left;
  color: #fff;
  font-size: 18px;
  /* line-height: 33px; */
  /* padding: 30px 0; */
}
.privacy_text h2 {
  color: #e38226;
  font-size: 50px;
  line-height: 60px;
  font-weight: 600;
}
.privacy_text h2 {
  color: #fff;
}
.privacy_text h4 {
  color: #e38226;
  margin-top: 25px;
  font-weight: normal;
  font-size: 40px;
}
.link_text {
  color: #e38226;
}
.number_text {
  color: #fff;
}
