/* .carousel {
  margin: 30px auto 60px;
  padding: 0 80px;
} */
.carousel .carousel-item {
  text-align: center;
  overflow: hidden;
}
.carousel .carousel-item h4 {
  font-family: "Varela Round", sans-serif;
}
.carousel .carousel-item img {
  max-width: 100%;
  display: inline-block;
}
.carousel .carousel-item .btn {
  border-radius: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  background: #a177ff;
  padding: 6px 15px;
  margin-top: 5px;
}
.carousel .carousel-item .btn:hover {
  background: #8c5bff;
}
.carousel .carousel-item .btn i {
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
}
.carousel .thumb-wrapper {
  margin: 5px;
  text-align: left;
  background: #fff;
  background-color: #313131;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); */
}
.carousel .thumb-content {
  padding: 15px;
  font-size: 13px;
  text-align: center;
  background-color: black !important;
  color: #c9c9c9;
  border: none;
}
/* .carousel-control-prev,
.carousel-control-next {
  height: 44px;
  width: 44px;
  background: none;
  margin: auto 0;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.8);
} */

.carousel-control-prev,
.carousel-control-next {
  /* margin-bottom: 60px !important; */
}

@media (max-width: 420px) {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}
.carousel-control-prev i,
.carousel-control-next i {
  font-size: 36px;
  /* position: relative; */
  top: 50%;
  display: inline-block;
  margin: -19px 0 0 0;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
}
.carousel-control-prev i {
  margin-right: 180px !important;
  color: #ba6e3f;
}
.carousel-control-next i {
  margin-left: 150px !important;
  color: #ba6e3f;
}

@media (max-width: 850px) {
  .carousel-control-prev i {
    display: none;
  }
  .carousel-control-next i {
    display: none;
  }
}
.carousel-indicators {
  bottom: -50px;
}
.carousel-indicators li,
.carousel-indicators li.active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: -8px 4px;
  border: none;
}
.carousel-indicators li {
  background: #ababab;
}
.carousel-indicators li.active {
  background: #555;
}
.reviews {
  background-color: #131313;
  padding: 20px 0px 50px;
  display: flex;
  justify-content: center;
  color: #fff;
  flex-wrap: wrap;
}
.games_header {
  text-align: center;
  color: #e38226;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
}
.games_desc {
  color: #fff;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
  font-weight: 700;
}

.cards {
  width: 100%;
}

.review-container {
  display: flex;
  justify-content: center;
  color: #fff;
  /* align-items: center; */
}
