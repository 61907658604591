.signIn {
  margin: 0;
  padding: 0;
  background-image: url("../../assets/images/signInBg.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  min-width: 100vw;
}

.form_sign {
  min-height: 100% !important;
  padding-top: 70px !important;
}

.signin_form {
  min-height: 88% !important;
  background-color: #313131 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  max-width: 45% !important;
  margin: 0px auto !important;
  padding: 50px 0;
}

.signin_form h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  color: #e38226;
  margin-bottom: 45px;
  text-align: center;
}

.signin_inp {
  background: transparent !important;
  width: 75% !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 30px 10px !important;
  margin-top: 30px !important;
  position: relative;
  /* border: 1px solid red !important; */
}

.asterisk {
  color: #e38226;
  position: absolute;
  font-weight: bold;
  margin-left: 3px;
  font-size: 1.3em;
  right: 11%;
  top: 46%;
}
.signinput_inp {
  background: transparent !important;
  width: 75% !important;
  margin: 10px auto !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 0 !important;
  color: #fff !important;
  padding: 30px 10px !important;
  margin-top: 30px !important;
}

.signUp_Btn {
  padding: 12px 35px !important;
  border: none !important;
  background-color: #e38226 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 71% !important;
  border-radius: 0px !important;
}

.eyeHandler {
  position: relative;
  width: 100%;
}

.eye {
  position: absolute;
  right: 17%;
  top: 45%;
  cursor: pointer;
}

.hr_lines {
  background: rgb(255, 255, 255) !important;
  width: 45% !important;
  height: 2px !important;
  z-index: 100;
}

.or_text {
  color: #fff;
}

.icon_divs {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 30px;
}

.icon_boxes {
  height: 55px;
  width: 130px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.signIn_content {
  margin-top: 30px;
}

.register_main_text {
  color: #9e9e9e;
  font-size: 16px;
}

.register_text {
  color: #e38226;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none !important;
}
@media (max-width: 1024px) {
  .signin_form h1 {
    font-weight: 700;
    font-size: 46px;
    line-height: 70px;
    color: #e38226;
    margin-bottom: 45px;
    text-align: center;
  }
  .signin_form {
    min-width: 45%;
  }
}
@media (max-width: 768px) {
  .signin_form {
    min-width: 65%;
  }
}
@media (max-width: 425px) {
  .signin_form {
    min-width: 95%;
  }
}
.basic_upload_form {
  min-height: 88% !important;
  background-color: #313131 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  max-width: 45% !important;
  margin: 0px auto !important;
  padding: 80px 0 !important;
}
.basic_upload_form h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 70px;
  color: #e38226;
  margin-bottom: 45px;
  text-align: center;
}
@media (max-width: 1024px) {
  .basic_upload_form {
    max-width: 65% !important;
  }
}
@media (max-width: 768px) {
  .basic_upload_form {
    max-width: 90% !important;
    padding: 60px 0 !important;
  }

  .basic_upload_form h1 {
    font-size: 46px;
  }
}
